import { Tabs } from "antd";

const CourtDashboard = () => {

    const onChange = (key) => {
        console.log(key);
    };
    const items = [
        {
            key: '1',
            label: 'Overview',
            children: 'Content of Overview Court User',
        },
        {
            key: '2',
            label: 'Statistics',
            children: 'Content of Statistics Court User',
        }
    ];

    return (
        <>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
        </>
    )
}

export default CourtDashboard