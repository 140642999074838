import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons"
import { Button, Col, Form, Input, Modal, Row, Space, Table, Typography, notification } from "antd"
import { useEffect, useState } from "react"
import ajaxService from "../../../services/ajax-service"
import { Filter } from "../../../components"

const { Text, Title } = Typography

const Questions = () => {

    const [form] = Form.useForm();

    const [filter, setFilter] = useState('')
    const [showModal, setShowModal] = useState(false)

    const [dataSource, setDataSource] = useState({})

    const columns = [
        {
            title: 'Question',
            dataIndex: 'question',
            key: 'question',
            render: (question) => <Text>Q : {question}</Text>
        },
        {
            title: 'Actions',
            key: 'address',
            render: (text, record) => (
                <Space size='small'>
                    <Button type='primary' size="small" style={{ background: "#EBDEE1", color: '#FF9595', fontSize: '16px' }} danger
                    // onClick={() => openDeleteModal(record.id)}
                    >
                        <DeleteOutlined />
                    </Button>
                </Space>
            )
        },
    ]

    const onFinish = async (values) => {

        const response = await ajaxService.post('/questions', values)
        if (response.success) {
            handleCancel();
            initialize();
            notification.open({ message: response.message, icon: <CheckCircleOutlined style={{ color: '#108ee9' }} /> })
        } else {
            notification.open({ message: "Unable to add question.", icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
        }


        console.log(values)
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed: ' + errorInfo)
    }

    const handleSearch = async ({ name }) => {
        const response = await ajaxService.get(`/questions?question=${name}`)

        const { success, data } = response;

        if (success) {

            if (data.data.length == 0) {
                setFilter('')
                notification.open({ message: `${name} is not found!`, icon: <CloseCircleOutlined style={{ color: 'red' }} /> })
            } else {
                setFilter(name)
            }

            setDataSource(response.data)
        }
        console.log(name)

    }

    const handleChangePage = async (page) => {
        const response = await ajaxService.get(`/questions?question=${filter}&page=${page}`)
        if (response.success) {
            setDataSource(response.data)
        }
    }

    const initialize = async () => {
        const response = await ajaxService.get(`/questions?question=${filter}`);
        if (response.success) {
            setDataSource(response.data)
        }
    }

    useEffect(() => {
        initialize()
    }, [])

    const handleCancel = () => {
        setShowModal(false)
        form.resetFields()
    }

    const renderModal = (
        <Modal
            className="court-modal second-modal"
            width="35%"
            open={showModal}
            maskClosable={false}
            onCancel={handleCancel}
            footer={null}
        >
            <div style={{ padding: '15px 20px', marginTop: 25 }}>
                <div style={{ marginBottom: 20, textAlign: 'center' }}>
                    <Title id='modal-title' level={3}>Add New Question</Title>
                </div>
                <Form form={form} layout="vertical" onFinish={onFinish} onFinishFailed={onFinishFailed}>
                    <Row gutter={16}>
                        <Col span={24}>
                            <Form.Item label={<b>Please write the question below</b>} name="question" rules={[{ required: true, message: 'Question is required' }]}>
                                <Input addonBefore="Q " placeholder="Write questions here" allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <div style={{ textAlign: 'center' }}>
                        <Button key="finish" type="primary" onClick={form.submit} style={{ background: 'var(--themeColor)' }} className="modal-button">
                            Add
                        </Button>
                    </div>
                </Form>
            </div>
        </Modal >
    )

    return (
        <>
            <Space style={{ display: 'flex', justifyContent: 'space-between' }}>

                <Filter handleSearch={handleSearch} placeholder="Search question" />

                <Button type="primary" style={{ background: 'var(--themeColor)' }} onClick={() => setShowModal(true)}>Add New Question</Button>
            </Space>

            <Table
                columns={columns}
                dataSource={dataSource.data}
                style={{ background: 'none' }}
                rowKey='id'
                pagination={{
                    onChange: (page, size) => handleChangePage(page),
                    current: dataSource.current_page,
                    pageSize: dataSource.per_page,
                    total: dataSource.total,
                    showSizeChanger: false
                }}
            />
            {renderModal}
        </>
    )
}

export default Questions